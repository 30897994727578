const path = require('path')
const proxy = require('http-proxy-middleware');

require('dotenv').config({
    path: `.env`
})

module.exports = {
    siteMetadata: {
        siteUrl: `https://mw-training.co.uk`
    },
    developMiddleware: app => {
    app.use(
      "/.netlify/functions/",
      proxy({
        target: "http://localhost:9000",
        pathRewrite: {
          "/.netlify/functions/": "",
        },
      })
    )
  },
    plugins: [
        {
            resolve: `gatsby-plugin-google-analytics`,
            options: {
                trackingId: 'UA-177038537-1',
                defer: false
            }
        },
        {
            resolve: 'gatsby-plugin-sitemap',
            options: {
                exclude: [`/upcoming-courses/*`, `/editor`, '/cookies']
            }
        },
        {
            resolve: 'gatsby-source-storyblok',
            options: {
                accessToken: 'tteRszVttwgbIsYIuLiUXQtt',
                homeSlug: 'home',
                version: 'draft'
            }
        },
        {
            resolve: `gatsby-plugin-sass`,
            options: {
                includePaths: [path.resolve(__dirname, 'src/common/styles/global.scss')]
            }
        },
        'gatsby-plugin-typescript',
        {
            resolve: `gatsby-plugin-alias-imports`,
            options: {
                alias: {
                    '@components': path.resolve(__dirname, 'src/components'),
                    '@constants': path.resolve(__dirname, 'src/constants'),
                    '@helpers': path.resolve(__dirname, 'src/helpers'),
                    '@common': path.resolve(__dirname, 'src/common'),
                    '@layouts': path.resolve(__dirname, 'src/layouts')
                },
                extensions: []
            }
        }
    ]
}
